import React from "react";
import { graphql, PageProps } from "gatsby";

import getReadablePrice from "../utils/getReadablePrice";

import SEO from "../components/SEO";
import NavigationScrollbar from "../components/NavigationScrollbar";
import { PaddingContainer } from "../components/Containers";
import {
  ProductDetailInformation,
  DownloadBlueprints,
  Breadcrumb,
  ProductDetailHero,
} from "../components/ProductDetail";

const EnglishProductDetailPage: React.FC<PageProps> = ({ location, data }) => {
  const strapiData: any = data;
  const product: any = strapiData.product;

  const { name, description, content, brochures, price, productCategory } =
    product;
  const { slug, name: productCategoryName } = productCategory;

  return (
    <>
      <SEO title={name} lang="en" pathName={location.pathname} />
      <NavigationScrollbar />
      <PaddingContainer>
        <Breadcrumb
          productCategory={{ slug, name: productCategoryName }}
          product={product}
        />
        <ProductDetailHero product={product} price={getReadablePrice(price)}>
          <p>{description}</p>
        </ProductDetailHero>
        <ProductDetailInformation
          title="Product Information"
          content={content}
        />
      </PaddingContainer>
      {brochures && brochures.length > 0 && (
        <DownloadBlueprints brochures={brochures} language="en" />
      )}
    </>
  );
};

export const productCategoryQuery = graphql`
  query EnglishProductDetailQuery($productSlug: String!) {
    product: strapiProduct(slug: { eq: $productSlug }) {
      slug
      price
      shippingCost
      id: productId
      name: englishName
      description: englishDescription
      content: englishContent
      coverImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1680
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      productCategory {
        slug
        name: englishName
      }
      brochures: englishBrochures {
        id
      }
    }
  }
`;

export default EnglishProductDetailPage;
